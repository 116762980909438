<template>
    <div
      v-if="this.eJSON.globalProps.logos.length"
      class="p-2 w-100"
      :style="{
        position: 'absolute',
        bottom: '0',
        backgroundColor: '#242526',
      }"
    >
      <img
        :src="$store.getters.environmentLogoURL"
        onerror="this.style.display='none'"
        :style="{
          maxHeight: '40px',
          maxWidth: '100%',
          width: 'auto',
        }"
      />
      <span
        class="p-2"
        :style="{
          color: 'grey',
          fontSize: '12px',
        }"
      >
        © {{ new Date().getFullYear() }}
        <span
          v-if="environmentInfo && environmentInfo.environmentName"
          >{{ environmentInfo.environmentName }}.</span
        >
        All Rights Reserved - v0.1.1
      </span>
    </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "Footer",
  computed: {
    ...mapState(["eJSON", "environmentInfo"]),
    footerProps() {
      // SOMETIME KEY FOR FOOTER block IS like this 'iaSbEJRI4bQj6f4B' not always 'Footer'
      // So Filtered By Title
      return Object.entries(this.eJSON.blockLibrary).filter(
        (el) => el[1].title === "Footer",
      )[0][1].props
    },
  },
}
</script>
