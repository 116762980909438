var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.eJSON.globalProps.logos.length)?_c('div',{staticClass:"p-2 w-100",style:({
    position: 'absolute',
    bottom: '0',
    backgroundColor: '#242526',
  })},[_c('img',{style:({
      maxHeight: '40px',
      maxWidth: '100%',
      width: 'auto',
    }),attrs:{"src":_vm.$store.getters.environmentLogoURL,"onerror":"this.style.display='none'"}}),_c('span',{staticClass:"p-2",style:({
      color: 'grey',
      fontSize: '12px',
    })},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" "),(_vm.environmentInfo && _vm.environmentInfo.environmentName)?_c('span',[_vm._v(_vm._s(_vm.environmentInfo.environmentName)+".")]):_vm._e(),_vm._v(" All Rights Reserved - v0.1.1 ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }